import * as React from "react"

import { Link, graphql } from "gatsby"
import _ from "lodash"

import Layout from "./layout"
import Seo from "./seo"
import "../css/richContent.css"
import "../css/page.css"

const Page = ({ location, data }) => {
  if (data.page) {
    const { page } = data

    return (
      <Layout>
        <Seo
          title={page.title}
          pathname={location.pathname}
          description={_.truncate(page.content.data.content.replace(/(<([^>]+)>)/gi, ""), {
            length: 130,
          })}
        />
        <div className="constrained-container blog-post">
          <h1>{page.title}</h1>
          <div
            className={`rich-content page ${page.slug}`}
            dangerouslySetInnerHTML={{ __html: page.content.data.content }}
          />
        </div>
      </Layout>
    )
  } else {
    return null
  }
}

export default Page

export const query = graphql`
  query PageQuery($slug: String) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    page: strapiPage(slug: { eq: $slug, ne: "" }) {
      # slug
      title
      content {
        data {
          content
        }
      }
    }
  }
`
